const $analyseLaunched = document.querySelector<HTMLInputElement>('input#analyse-launched[type="checkbox"]');

let submitted = false;
// Prevent double submissions
$analyseLaunched?.form?.addEventListener('submit', function (e) {
	if (submitted) {
		e.preventDefault();
		e.stopImmediatePropagation();
	} else {
		console.debug('Submited !')
		const submit = this.querySelector<HTMLButtonElement>('button[type="submit"]');
		if (submit) {
			submit.disabled = true;
		}
	}
	submitted = true;
});
